import React, { createContext, useReducer, useContext } from 'react';
import { createPortal } from 'react-dom';
import Toasts from '@/components/common/toasts/Toasts';

export const ToastContext = createContext();

const initialState = [];

export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
export const REMOVE_ALL = 'REMOVE_ALL';

export const toastReducer = (state, action) => {
  switch (action.type) {
    case ADD:
      return [
        {
          id: +new Date(),
          content: action.payload.content,
          type: action.payload.type,
        },
        ...state,

      ];
    case REMOVE:
      return state.filter((t) => t.id !== action.payload.id);
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
};

export const ToastProvider = ({ children }) => {
  const [toasts, toastDispatch] = useReducer(toastReducer, initialState);

  const addToast = (content, type) => {
    toastDispatch({
      type: ADD,
      payload: {
        content, type,
      },
    });
  };

  const removeToast = (id) => {
    toastDispatch({
      type: REMOVE,
      payload: {
        id,
      },
    });
  };

  const toastData = { toasts, addToast, removeToast };
  return (
    <ToastContext.Provider value={toastData}>
      {children}
      {createPortal(<Toasts toasts={toasts} removeToast={removeToast} />, document.body)}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);

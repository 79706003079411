import 'bootstrap/dist/css/bootstrap.min.css';
import '@/lib/style/style.scss';
import '@/lib/style/new-stuff.scss';
import '@/lib/style/dashboard-style.scss';
import 'react-phone-input-2/lib/style.css';
import '@/lib/style/onboarding.scss';
import '@/lib/style/dashboard.scss';
import '@/lib/style/buttons.scss';

import React from 'react';
import { SWRConfig } from 'swr';
import { AppWrapper } from '@/lib/context/useUserContext';
import fetcher from '@/lib/util/fetcher';
import { ToastProvider } from '@/lib/context/useToastContext';
import AppThemeProvider from '@/lib/themes/AppThemeProvider';
import Sentry from '@/lib/util/Sentry';
import { overpass, dosis } from '@/lib/themes/fonts';

// This default export is required in a new `pages/_app.js` file.
export default function App({ Component, pageProps }) {
  return (
    <main className={`${overpass.variable} ${dosis.variable}`}>
      <Sentry.ErrorBoundary fallback="An error has occurered!">
        <AppWrapper>
          <AppThemeProvider>
            <ToastProvider>
              <SWRConfig
                value={{
                  fetcher,
                }}
              >
                <Component {...pageProps} />
              </SWRConfig>
            </ToastProvider>
          </AppThemeProvider>
        </AppWrapper>
      </Sentry.ErrorBoundary>
    </main>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Toast } from 'react-bootstrap';
import CloseButton from "@/components/common/widgets/closebutton";
import {AiFillWarning, AiFillCheckCircle, AiFillInfoCircle} from 'react-icons/ai';


const propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeToast: PropTypes.func.isRequired,
};

function Toasts({ toasts, removeToast }) {
  const convertEpochToTimeAgo = (epochNumber) => {
    const d = new Date(); // Gets the current time
    const nowTs = d.getTime();
    const millisecondDifference = nowTs - epochNumber;
    const seconds = Math.floor(millisecondDifference / 1000);

    // Could insert more times here
    if (seconds > 1800) {
      return 'Half an hour ago';
    }
    if (seconds > 60) {
      return `${Math.floor(seconds / 60)} minutes ago`;
    }
    return `${seconds} seconds ago`;
  };

  const getStyleForType = (type) => {
    switch (type) {
      case 'success':
        return { backgroundColor: 'green', color: "white" };
      case 'error':
        return { backgroundColor: 'rgb(190, 0, 0)', color: "white" };
      case 'warn':
        return { backgroundColor: '#cfac00', color: 'white'};
      default:
        return { backgroundColor: "gray", color: "white"};
    }
  };


  function fadeOut(id) {
    var op = 1;  // initial opacity
    var margin = 1;
    var element = document.getElementsByClassName(id)[0];
    var timer = setInterval(function () {
        if (op <= 0.01){
            clearInterval(timer);
            element.style.display = 'none';
            removeToast(id);
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        element.style.marginTop = "-" + margin + "px";
        margin += margin * .5;
        op -= op * 0.5;
    }, 50);
}

  return (
    <div
      style={{
        position: 'fixed',
        top: 15,
        left: "50%",
        transform: "translate(-50%, 0)",
        zIndex: 10000,
      }}
    >
      {toasts.map(({ id, content, type }) => (
        <Toast
          key={id}
          onClose={() => fadeOut(id)}
          className={(type || '') + " " + id}
          delay={2000}
          style={getStyleForType(type)}
          // show
          autohide
          animation={true}
        >
          {/* <Toast.Header>
            <b className="mr-auto"></b>
            <small>{convertEpochToTimeAgo(id)}</small>
          </Toast.Header> */}
          <Toast.Body>
          {type === "error"?<div className = "toast-icon"><AiFillWarning/></div>:null}
          {type === "success"?<div className = "toast-icon"><AiFillCheckCircle/></div>:null}
          {type === "warn"?<div className = "toast-icon"><AiFillWarning/></div>:null}
          {type === "error" || type === "success" || type === "warn"?null:<div className = "toast-icon"><AiFillInfoCircle/></div>}

            {content} 
            <CloseButton
              handleClose={fadeOut}
              id = {id}
            />
          </Toast.Body>
        </Toast>
      ))}

    </div>
  );
}

Toasts.propTypes = propTypes;

export default Toasts;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
// import { useRouter } from 'next/dist/client/router';
import Sentry from '@/lib/util/Sentry';
import UserAPI from '../api/UserAPI';

const UserContext = createContext();

/* I would use router.push for redirects, but that doesn't always wait
    for localStorage */
export function AppWrapper({ children }) {
  // const router = useRouter();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);

  const redirectToAuthenticatedPage = () => {
    // redirect to 'from' URL (if specified) or dashboard
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('from')) {
      window.location.href = urlParams.get('from');
    } else {
      window.location.href = '/dashboard/match';
    }
  };

  const signIn = (userData, { noRedirect = false } = {}) => {
    localStorage.setItem('bm-user', JSON.stringify(userData));
    localStorage.setItem('loggedIn', Buffer.from(userData.email).toString('base64'));
    setUser(userData);
    if (!noRedirect) {
      redirectToAuthenticatedPage();
    }
  };

  const signOut = () => {
    localStorage.removeItem('bm-user');
    localStorage.removeItem('loggedIn');
    window.location.href = '/';
  };

  const redirectToLogin = () => {
    localStorage.removeItem('bm-user');
    localStorage.removeItem('loggedIn');
    window.location.href = `/login?from=${window.location.pathname}`;
  };

  const refreshUser = async (email) => {
    const userData = await UserAPI.get(email);
    setUser(userData);
    localStorage.setItem('bm-user', JSON.stringify(userData));
  };

  useEffect(() => {
    const loggedInEmail = localStorage.getItem('loggedIn');
    const storedUser = localStorage.getItem('bm-user');
    if (loggedInEmail) {
      const email = Buffer.from(loggedInEmail, 'base64').toString('ascii');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);

        Sentry.setContext('user', {
          email: parsedUser.email,
          name: parsedUser.name,
        });
        setUser(parsedUser);
        setIsValid(true);
        setIsLoading(false);
      } else {
        // Technically, there's a bug here.
        // The API returns valid tokens when passed in an empty
        // cookie body (throwing an error does not stop execution after parseCookie).
        // Therefore, calling the API once without the properly scoped cookie
        // (since the cookie is scoped as /oauth/ or /login/ instead of /user/)
        // actually gives us a valid cookie
        UserAPI.get(email).then((userData) => {
          setUser(userData);
          localStorage.setItem('bm-user', JSON.stringify(userData));
          setIsValid(true);
          setIsLoading(false);

          // This is the sketchy hacked fix -- after we get a correct token,
          // this reloads the user object.
          // check if userData.email is defined, as that indicates a valid user object.
          if (!userData.email) {
            UserAPI.get(email).then((moreUserData) => {
              setUser(moreUserData);
              localStorage.setItem('bm-user', JSON.stringify(moreUserData));
              setIsValid(true);
              setIsLoading(false);
            });
          }

          // If the token has expired, redirect to login page.
        }).catch(redirectToLogin);
      }
    } else {
      setIsValid(false);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('beforeunload', () => {
        localStorage.removeItem('bm-user');
      });
    }
  }, []);

  const sharedState = {
    user,
    isLoading,
    signIn,
    signOut,
    isValid,
    redirectToLogin,
    refreshUser,
  };

  if (isLoading) return null;

  return (
    <UserContext.Provider value={sharedState}>
      {children}
    </UserContext.Provider>
  );
}

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useUserContext() {
  return useContext(UserContext);
}

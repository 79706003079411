import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  main {
    font-family: var(--font-overpass), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    line-height: 1.8;
    font-weight: 300;
    color: #555555; 
  }

  button:focus {
    outline: none;
}
`;

export default GlobalStyle;
